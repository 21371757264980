import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppGuard } from './guards/app-guard';
import { AppRouteLink } from './shared/enums/app-route-link';

const routes: Routes = [
  {
    path: ':clientKey',
    canActivate: [ AppGuard ],
    children: [
      { 
        path: AppRouteLink.ESTIMATE, 
        loadChildren: () => import('./modules/estimate/estimate.module').then(m => m.EstimateModule) 
      },
      { 
        path: AppRouteLink.TEXT, 
        loadChildren: () => import('./modules/text/text.module').then(m => m.TextModule) 
      },

      { 
        path: AppRouteLink.TREATMENTEVOLUTION, 
        loadChildren: () => import('./modules/treatment-evolution/treatment-evolution.module').then(m => m.TreatmentEvolutionModule) 
      },
      { 
        path: AppRouteLink.ANAMNESE, 
        loadChildren: () => import('./modules/anamnese/anamnese.module').then(m => m.AnamneseModule) 
      },
      {
        path: '',
        redirectTo: AppRouteLink.ESTIMATE,
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
