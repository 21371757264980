import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpApiService } from '../shared/services/http-api.service';

@Injectable()
export class AppGuard implements CanActivate {

    constructor(private httpApiService: HttpApiService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): 
        boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        const params = route.params;
        if (params && params.clientKey) {
            this.httpApiService.setClientKey(params.clientKey);
            return true;
        } else {
            return false;
        }
    }

}