import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpApiService {

  private baseUrl = environment.baseUrl;
   
  private clientKey!: string;
  private $clientKey: BehaviorSubject<any> = new BehaviorSubject(null);

  onClientKey = this.$clientKey.asObservable();
  
  constructor() {
    this.$clientKey
      .pipe(filter(x => !!x))
      .subscribe(clientKey => {
        this.clientKey = clientKey;
        this.baseUrl = `${this.baseUrl}/${clientKey}`;
      })
  }

  getBasePath(): string{
    return this.baseUrl;
  }

  getClientKey(){
    return this.clientKey
  }

  setClientKey(clientKey: string): void {
    if (!this.clientKey) {
      this.$clientKey.next(clientKey);
    }
  }
}
